import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { slide as Menu } from "react-burger-menu"

import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const PrivacyPage = ({ data }) => (
  <Layout>
    <SEO title="Privacy Policy" keywords={configs.app_keywords} />
    <Menu right>
      <a id="home" className="menu-item" href="/">
        Home
      </a>
      <a id="faq" className="menu-item" href="/faq/">
        FAQ
      </a>
      <a id="privacy-policy" className="menu-item" href="/privacy">
        Privacy Policy
      </a>
      <a id="terms-and-conditions" className="menu-item" href="/terms">
        Terms and Conditions
      </a>
      <a id="tutorials" className="menu-item" href="/tutorials">
        Tutorials
      </a>
      <a id="about" className="menu-item" href="/about">
        About
      </a>
    </Menu>
    <div>
      <div className="container-terms">
        <header>
          <div className="logo">
            <div className="appIconShadow">
              <svg width="0" height="0">
                <defs>
                  <clipPath id="shape">
                    <path
                      id="shape"
                      d="M6181.23,233.709v-1.792c0-.5-0.02-1-0.02-1.523a24.257,24.257,0,0,0-.28-3.3,11.207,11.207,0,0,0-1.04-3.132,10.683,10.683,0,0,0-1.95-2.679,10.384,10.384,0,0,0-2.68-1.943,10.806,10.806,0,0,0-3.13-1.038,19.588,19.588,0,0,0-3.3-.285c-0.5-.017-1-0.017-1.52-0.017h-22.39c-0.51,0-1.01.017-1.53,0.017a24.041,24.041,0,0,0-3.3.285,11.009,11.009,0,0,0-3.13,1.038,10.491,10.491,0,0,0-4.62,4.622,10.893,10.893,0,0,0-1.04,3.132,19.2,19.2,0,0,0-.28,3.3c-0.02.5-.02,1-0.02,1.523v22.392c0,0.5.02,1,.02,1.524a24.257,24.257,0,0,0,.28,3.3,10.9,10.9,0,0,0,1.04,3.132,10.491,10.491,0,0,0,4.62,4.622,11.04,11.04,0,0,0,3.13,1.038,19.891,19.891,0,0,0,3.3.285c0.51,0.017,1.01.017,1.53,0.017h22.39c0.5,0,1-.017,1.52-0.017a24.221,24.221,0,0,0,3.3-.285,10.836,10.836,0,0,0,3.13-1.038,10.408,10.408,0,0,0,2.68-1.943,10.683,10.683,0,0,0,1.95-2.679,11.217,11.217,0,0,0,1.04-3.132,20.257,20.257,0,0,0,.28-3.3c0.02-.5.02-1,0.02-1.524v-20.6h0Z"
                      transform="translate(-6131 -218)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <Img
                fluid={data.headerIcon.childImageSharp.fluid}
                className="headerIcon"
              />
            </div>
            <p
              className="headerName"
              style={{ color: configs.footer_text_color }}
            >
              {configs.app_name}
            </p>
          </div>
        </header>
        <div className="content-body">
          <h1>Privacy Policy</h1>

          <p>
            We care about data privacy and security; please review our{" "}
            <a href="/privacy">Privacy Policy</a> below. By using the Site, you
            agree to be bound by our Privacy Policy, which is incorporated into
            these Terms of Use. Please be advised the Site is hosted in the
            United States. If you access the Site from the European Union, Asia,
            or any other region of the world with laws or other requirements
            governing personal data collection, use, or disclosure that differ
            from applicable laws in the United States, then through your
            continued use of the Site or Services, you are transferring your
            data to the United States, and you expressly consent to have your
            data transferred to and processed in the United States. [Further, we
            do not knowingly accept, request, or solicit information from
            children or knowingly market to children. Therefore, in accordance
            with the U.S. Children&rsquo;s Online Privacy Protection Act, if we
            receive actual knowledge that anyone under the age of 13 has
            provided personal information to us without the requisite and
            verifiable parental consent, we will delete that information from
            the Site as quickly as is reasonably practical.]
          </p>
          <h2>DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</h2>
          <h3>Notifications</h3>
          <p>
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Site infringes
            upon any copyright you own or control, please immediately notify our
            Designated Copyright Agent using the contact information provided
            below (a &ldquo;Notification&rdquo;). A copy of your Notification
            will be sent to the person who posted or stored the material
            addressed in the Notification. Please be advised that pursuant to
            federal law you may be held liable for damages if you make material
            misrepresentations in a Notification. Thus, if you are not sure that
            material located on or linked to by the Site infringes your
            copyright, you should consider first contacting an attorney.
          </p>
          <p>
            All Notifications should meet the requirements of DMCA 17 U.S.C.
            &sect; 512(c)(3) and include the following information: (1) A
            physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed; (2) identification of the copyrighted work claimed to
            have been infringed, or, if multiple copyrighted works on the Site
            are covered by the Notification, a representative list of such works
            on the Site; (3) identification of the material that is claimed to
            be infringing or to be the subject of infringing activity and that
            is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit us to locate the
            material; (4) information reasonably sufficient to permit us to
            contact the complaining party, such as an address, telephone number,
            and, if available, an email address at which the complaining party
            may be contacted; (5) a statement that the complaining party has a
            good faith belief that use of the material in the manner complained
            of is not authorized by the copyright owner, its agent, or the law;
            and (6) a statement that the information in the notification is
            accurate, and under penalty of perjury, that the complaining party
            is authorized to act on behalf of the owner of an exclusive right
            that is allegedly infringed upon.
          </p>
          <h3>Counter Notification</h3>
          <p>
            If you believe your own copyrighted material has been removed from
            the Site as a result of a mistake or misidentification, you may
            submit a written counter notification to [us/our Designated
            Copyright Agent] using the contact information provided below (a
            &ldquo;Counter Notification&rdquo;). To be an effective Counter
            Notification under the DMCA, your Counter Notification must include
            substantially the following: (1) identification of the material that
            has been removed or disabled and the location at which the material
            appeared before it was removed or disabled; (2) a statement that you
            consent to the jurisdiction of the Federal District Court in which
            your address is located, or if your address is outside the United
            States, for any judicial district in which we are located; (3) a
            statement that you will accept service of process from the party
            that filed the Notification or the party's agent; (4) your name,
            address, and telephone number; (5) a statement under penalty of
            perjury that you have a good faith belief that the material in
            question was removed or disabled as a result of a mistake or
            misidentification of the material to be removed or disabled; and (6)
            your physical or electronic signature.
          </p>
          <p>
            If you send us a valid, written Counter Notification meeting the
            requirements described above, we will restore your removed or
            disabled material, unless we first receive notice from the party
            filing the Notification informing us that such party has filed a
            court action to restrain you from engaging in infringing activity
            related to the material in question. Please note that if you
            materially misrepresent that the disabled or removed content was
            removed by mistake or misidentification, you may be liable for
            damages, including costs and attorney's fees. Filing a false Counter
            Notification constitutes perjury.
          </p>
          <p>ATTN: Designated Copyright Agent</p>
          <p>picnplace.corp@gmail.com</p>
          <h2>COPYRIGHT INFRINGEMENTS</h2>
          <p>
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the Site infringes
            upon any copyright you own or control, please immediately notify us
            using the contact information provided below (a
            &ldquo;Notification&rdquo;). A copy of your Notification will be
            sent to the person who posted or stored the material addressed in
            the Notification. Please be advised that pursuant to federal law you
            may be held liable for damages if you make material
            misrepresentations in a Notification. Thus, if you are not sure that
            material located on or linked to by the Site infringes your
            copyright, you should consider first contacting an attorney.]
          </p>
          <h2>TERM AND TERMINATION</h2>
          <p>
            These Terms of Use shall remain in full force and effect while you
            use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF
            USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
            NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
            BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
            NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF
            USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
            USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR ACCOUNT AND] ANY
            CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING,
            IN OUR SOLE DISCRETION.
          </p>
          <p>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </p>
          <h2>MODIFICATIONS AND INTERRUPTIONS</h2>
          <p>
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time. We
            will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Site.
          </p>
          <p>
            We cannot guarantee the Site will be available at all times. We may
            experience hardware, software, or other problems or need to perform
            maintenance related to the Site, resulting in interruptions, delays,
            or errors. We reserve the right to change, revise, update, suspend,
            discontinue, or otherwise modify the Site at any time or for any
            reason without notice to you. You agree that we have no liability
            whatsoever for any loss, damage, or inconvenience caused by your
            inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these Terms of Use will be
            construed to obligate us to maintain and support the Site or to
            supply any corrections, updates, or releases in connection
            therewith.
          </p>
          <h2>GOVERNING LAW</h2>
          <p>
            These Terms of Use and your use of the Site are governed by and
            construed in accordance with the laws of the State of [name of
            state]applicable to agreements made and to be entirely performed
            within the State/Commonwealth of California, without regard to its
            conflict of law principles.
          </p>
          <h2>DISPUTE RESOLUTION</h2>
          <p>
            <strong>Option 1</strong>: Any legal action of whatever nature
            brought by either you or us (collectively, the &ldquo;Parties&rdquo;
            and individually, a &ldquo;Party&rdquo;) shall be commenced or
            prosecuted in the state and federal courts located in [name of
            county] County, [name of state], and the Parties hereby consent to,
            and waive all defenses of lack of personal jurisdiction and forum
            non conveniens with respect to venue and jurisdiction in such state
            and federal courts. Application of the United Nations Convention on
            Contracts for the International Sale of Goods and the Uniform
            Computer Information Transaction Act (UCITA) are excluded from these
            Terms of Use. In no event shall any claim, action, or proceeding
            brought by either Party related in any way to the Site be commenced
            more than 1 year after the cause of action arose.
          </p>
          <h3>Option 2: Informal Negotiations</h3>
          <p>
            To expedite resolution and control the cost of any dispute,
            controversy, or claim related to these Terms of Use (each a
            "Dispute" and collectively, the &ldquo;Disputes&rdquo;) brought by
            either you or us (individually, a &ldquo;Party&rdquo; and
            collectively, the &ldquo;Parties&rdquo;), the Parties agree to first
            attempt to negotiate any Dispute (except those Disputes expressly
            provided below) informally for at least 365 days before initiating
            arbitration. Such informal negotiations commence upon written notice
            from one Party to the other Party.
          </p>
          <h3>Binding Arbitration</h3>
          <p>
            If the Parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association ("AAA")
            and, where appropriate, the AAA&rsquo;s Supplementary Procedures for
            Consumer Related Disputes ("AAA Consumer Rules"), both of which are
            available at the AAA website www.adr.org. Your arbitration fees and
            your share of arbitrator compensation shall be governed by the AAA
            Consumer Rules and, where appropriate, limited by the AAA Consumer
            Rules. [If such costs are determined to by the arbitrator to be
            excessive, we will pay all arbitration fees and expenses.] The
            arbitration may be conducted in person, through the submission of
            documents, by phone, or online. The arbitrator will make a decision
            in writing, but need not provide a statement of reasons unless
            requested by either Party. The arbitrator must follow applicable
            law, and any award may be challenged if the arbitrator fails to do
            so. Except where otherwise required by the applicable AAA rules or
            applicable law, the arbitration will take place in [name of county]
            County, [name of state]. Except as otherwise provided herein, the
            Parties may litigate in court to compel arbitration, stay
            proceedings pending arbitration, or to confirm, modify, vacate, or
            enter judgment on the award entered by the arbitrator.
          </p>
          <p>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
            state and federal courts located in [name of county] County, [name
            of state], and the Parties hereby consent to, and waive all defenses
            of lack of personal jurisdiction, and forum non conveniens with
            respect to venue and jurisdiction in such state and federal
            courts.&nbsp;Application of the United Nations Convention on
            Contracts for the International Sale of Goods and the Uniform
            Computer Information Transaction Act (UCITA) are excluded from these
            Terms of Use.
          </p>
          <p>
            In no event shall any Dispute brought by either Party related in any
            way to the Site be commenced more than 1 year after the cause of
            action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <h3>Option 3: Binding Arbitration</h3>
          <p>
            To expedite resolution and control the cost of any dispute,
            controversy or claim related to these Terms of Use (each a "Dispute"
            and collectively, &ldquo;Disputes&rdquo;), any Dispute brought by
            either you or us (individually, a &ldquo;Party&rdquo; and
            collectively, the &ldquo;Parties&rdquo;) shall be finally and
            exclusively resolved by binding arbitration. YOU UNDERSTAND THAT
            WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND
            HAVE A JURY TRIAL. The arbitration shall be commenced and conducted
            under the Commercial Arbitration Rules of the American Arbitration
            Association ("AAA") and, where appropriate, the AAA&rsquo;s
            Supplementary Procedures for Consumer Related Disputes ("AAA
            Consumer Rules"), both of which are available at the AAA website
            www.adr.org. Your arbitration fees and your share of arbitrator
            compensation shall be governed by the AAA Consumer Rules and, where
            appropriate, limited by the AAA Consumer Rules. [If such costs are
            determined to by the arbitrator to be excessive, we will pay all
            arbitration fees and expenses.] The arbitration may be conducted in
            person, through the submission of documents, by phone, or online.
            The arbitrator will make a decision in writing, but need not provide
            a statement of reasons unless requested by either Party. The
            arbitrator must follow applicable law, and any award may be
            challenged if the arbitrator fails to do so. Except where otherwise
            required by the applicable AAA rules or applicable law, the
            arbitration will take place in [name of county] County, [name of
            state]. Except as otherwise provided herein, the Parties may
            litigate in court to compel arbitration, stay proceedings pending
            arbitration, or to confirm, modify, vacate, or enter judgment on the
            award entered by the arbitrator.
          </p>
          <p>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
            state and federal courts located in [name of county] County, [name
            of state], and the Parties hereby consent to, and waive all defenses
            of lack of, personal jurisdiction, and forum non conveniens with
            respect to venue and jurisdiction in such state and federal
            courts.&nbsp;Application of the United Nations Convention on
            Contracts for the International Sale of Goods and the Uniform
            Computer Information Transaction Act (UCITA) are excluded from these
            Terms of Use.
          </p>
          <p>
            In no event shall any Dispute brought by either Party related in any
            way to the Site or Services be commenced more than 2 years after the
            cause of action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <h3>Option 2/Option 3: Restrictions</h3>
          <p>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually. To the full extent
            permitted by law, (a) no arbitration shall be joined with any other
            proceeding; (b) there is no right or authority for any Dispute to be
            arbitrated on a class-action basis or to utilize class action
            procedures; and (c) there is no right or authority for any Dispute
            to be brought in a purported representative capacity on behalf of
            the general public or any other persons.
          </p>
          <h3>
            Option 2/Option 3: Exceptions to [Informal Negotiations and]
            Arbitration
          </h3>
          <p>
            The Parties agree that the following Disputes are not subject to the
            above provisions concerning [informal negotiations and] binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a Party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither Party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </p>
          <h2>CORRECTIONS</h2>
          <p>
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions that may relate to the Site,
            including descriptions, pricing, availability, and various other
            information. We reserve the right to correct any errors,
            inaccuracies, or omissions and to change or update the information
            on the Site at any time, without prior notice.
          </p>
          <h2>DISCLAIMER</h2>
          <p>
            THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
            FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
            OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
            INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
            ACCURACY OR COMPLETENESS OF THE SITE&rsquo;S CONTENT OR THE CONTENT
            OF ANY WEBSITES LINKED TO THIS SITE AND WE WILL ASSUME NO LIABILITY
            OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION
            STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO
            OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
            WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY,
            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR
            FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
            OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
            THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
            WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
            ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
            RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
            THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
            OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
            YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
            APPROPRIATE.
          </p>
          <h2>LIMITATIONS OF LIABILITY</h2>
          <p>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
            TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
            PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM
            YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. [NOTWITHSTANDING ANYTHING TO THE
            CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
            WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
            TIMES BE LIMITED TO [THE LESSER OF] [THE AMOUNT PAID, IF ANY, BY YOU
            TO US DURING THE 12 MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
            ARISING [OR] $24. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
            IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
            DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
            DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
            ADDITIONAL RIGHTS.]
          </p>
          <h2>INDEMNIFICATION</h2>
          <p>
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys&rsquo;
            fees and expenses, made by any third party due to or arising out of:
            (1) [your Contributions]; (2) use of the Site; (3) breach of these
            Terms of Use; (4) any breach of your representations and warranties
            set forth in these Terms of Use; (5) your violation of the rights of
            a third party, including but not limited to intellectual property
            rights; or (6) any overt harmful act toward any other user of the
            Site with whom you connected via the Site. Notwithstanding the
            foregoing, we reserve the right, at your expense, to assume the
            exclusive defense and control of any matter for which you are
            required to indemnify us, and you agree to cooperate, at your
            expense, with our defense of such claims. We will use reasonable
            efforts to notify you of any such claim, action, or proceeding which
            is subject to this indemnification upon becoming aware of it.&nbsp;
          </p>
          <h2>USER DATA</h2>
          <p>
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the Site, as well as data relating to your use
            of the Site. Although we perform regular routine backups of data,
            you are solely responsible for all data that you transmit or that
            relates to any activity you have undertaken using the Site. You
            agree that we shall have no liability to you for any loss or
            corruption of any such data, and you hereby waive any right of
            action against us arising from any such loss or corruption of such
            data.
          </p>
          <h2>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
          <p>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email and on the Site, satisfy any legal
            requirement that such communication be in writing. YOU HEREBY AGREE
            TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
            RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
            RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
            SITE. You hereby waive any rights or requirements under any
            statutes, regulations, rules, ordinances, or other laws in any
            jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means.
          </p>
          <h2>CALIFORNIA USERS AND RESIDENTS</h2>
          <p>
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </p>
          <h2>MISCELLANEOUS</h2>
          <p>
            These Terms of Use and any policies or operating rules posted by us
            on the Site constitute the entire agreement and understanding
            between you and us. Our failure to exercise or enforce any right or
            provision of these Terms of Use shall not operate as a waiver of
            such right or provision. These Terms of Use operate to the fullest
            extent permissible by law. We may assign any or all of our rights
            and obligations to others at any time. We shall not be responsible
            or liable for any loss, damage, delay, or failure to act caused by
            any cause beyond our reasonable control. If any provision or part of
            a provision of these Terms of Use is determined to be unlawful,
            void, or unenforceable, that provision or part of the provision is
            deemed severable from these Terms of Use and does not affect the
            validity and enforceability of any remaining provisions. There is no
            joint venture, partnership, employment or agency relationship
            created between you and us as a result of these Terms of Use or use
            of the Site. You agree that these Terms of Use will not be construed
            against us by virtue of having drafted them. You hereby waive any
            and all defenses you may have based on the electronic form of these
            Terms of Use and the lack of signing by the parties hereto to
            execute these Terms of Use.
          </p>
          <h2>Privacy Policy</h2>
          <p>
            PicnPlace built the PicnPlace app as a Commercial app. This SERVICE
            is provided by PicnPlace and is intended for use as is.
          </p>
          <p>
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service.
          </p>
          <p>
            If you choose to use our Service, then you agree to the collection
            and use of information in relation to this policy. The Personal
            Information that we collect is used for providing and improving the
            Service. We will not use or share your information with anyone
            except as described in this Privacy Policy.
          </p>
          <p>
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which is accessible at PicnPlace unless
            otherwise defined in this Privacy Policy.
          </p>
          <p>
            <strong>Information Collection and Use</strong>
          </p>
          <p>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Name, Email ID, address. The
            information that we request will be retained by us and used as
            described in this privacy policy.
          </p>
          <p>
            The app does use third party services that may collect information
            used to identify you.
          </p>
          <p>
            Link to privacy policy of third party service providers used by the
            app
          </p>
          <ul>
            <li>
              <a href="https://www.google.com/policies/privacy/">
                Google Play Services
              </a>
            </li>
          </ul>
          <p>
            <strong>Log Data</strong>
          </p>
          <p>
            We want to inform you that whenever you use our Service, in a case
            of an error in the app we collect data and information (through
            third party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol
            (&ldquo;IP&rdquo;) address, device name, operating system version,
            the configuration of the app when utilizing our Service, the time
            and date of your use of the Service, and other statistics.
          </p>
          <p>
            <br />
            <br />
          </p>
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory.
          </p>
          <p>
            This Service does not use these &ldquo;cookies&rdquo; explicitly.
            However, the app may use third party code and libraries that use
            &ldquo;cookies&rdquo; to collect information and improve their
            services. You have the option to either accept or refuse these
            cookies and know when a cookie is being sent to your device. If you
            choose to refuse our cookies, you may not be able to use some
            portions of this Service.
          </p>
          <p>
            <strong>Service Providers</strong>
          </p>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            We want to inform users of this Service that these third parties
            have access to your Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>
          <p>
            <strong>Security</strong>
          </p>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>
          <p>
            <strong>Links to Other Sites</strong>
          </p>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>
          <p>
            <strong>Children&rsquo;s Privacy</strong>
          </p>
          <p>
            These Services do not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </p>
          <p>
            <strong>Changes to This Privacy Policy</strong>
          </p>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. These changes are effective immediately after they are posted
            on this page.
          </p>
        </div>
        <footer>
          <p className="footerText">
            built by{" "}
            {configs.your_link ? (
              <a href={configs.your_link}>{configs.your_name}</a>
            ) : (
              `${configs.your_name}`
            )}{" "}
            for picnplace
            {configs.your_city && ` in ${configs.your_city}`}
          </p>
          <div className="footerIcons">
            {configs.facebook_username && (
              <a
                href={`https://facebook.com/${configs.facebook_username}`}
                aria-label="Facebook"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-facebook fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.linkedin_username && (
              <a
                href={`https://www.linkedin.com/in/${configs.linkedin_username}`}
                aria-label="LinkedIn"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-linkedin fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.twitter_username && (
              <a
                href={`https://twitter.com/${configs.twitter_username}`}
                aria-label="Twitter"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-twitter fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.github_username && (
              <a
                href={`https://github.com/${configs.github_username}`}
                aria-label="GitHub"
              >
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fab fa-github fa-stack-1x" />
                </span>
              </a>
            )}

            {configs.email_address && (
              <a href={`mailto:${configs.email_address}`} aria-label="Email">
                <span className="fa-stack fa-1x">
                  <i className="socialIconBack fas fa-circle fa-stack-2x" />
                  <i className="socialIconTop fas fa-envelope fa-stack-1x" />
                </span>
              </a>
            )}
          </div>
        </footer>
        {/*TODO: Add App Store API */}
      </div>
    </div>
  </Layout>
)

export default PrivacyPage

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appStore: file(relativePath: { eq: "appstore.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    playStore: file(relativePath: { eq: "playstore.png" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iphoneScreen: file(relativePath: { glob: "screenshot/*.{png,jpg}" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoScreen: file(
      extension: { ne: "txt" }
      relativePath: { glob: "videos/*" }
    ) {
      publicURL
      extension
    }
    appIconLarge: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headerimage.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 714) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewBlack: file(relativePath: { eq: "black.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewBlue: file(relativePath: { eq: "blue.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewCoral: file(relativePath: { eq: "coral.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewWhite: file(relativePath: { eq: "white.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewYellow: file(relativePath: { eq: "yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
